<!--
 * @Author: 羚羊公子
 * @FilePath: \usdtpay\src\pages\Home.vue
-->
<template>
  <var-snackbar v-model:show="popTitle">输入内容不能为空！</var-snackbar>
  <!--遮罩层 -->
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper" @click.stop>
      <div class="block">
        <div class="atom-spinner">
          <div class="spinner-inner">
            <div class="spinner-line"></div>
            <div class="spinner-line"></div>
            <div class="spinner-line"></div>
            <!--Chrome renders little circles malformed :(-->
            <div class="spinner-circle">&#9679;</div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
  <div class="lygz">
    <div class="logo">
      <div class="title">平头哥快速交易平台</div>
      <div class="ptgorjax">
        <div class="ptg-logo">
          <img src="../assets/ptg.svg" alt="" />
        </div>
        <div class="join">
          <svg
            t="1697807843569"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="15916"
            data-spm-anchor-id="a313x.search_index.0.i6.51fd3a81VJzPo0"
            width="60"
            height="60"
          >
            <path
              d="M420 691h-74c-98.1 0-178-79.9-178-178s79.9-178 178-178h73c19.9 0 36 16.1 36 36s-16.1 36-36 36h-73c-58.4 0-106 47.6-106 106s47.6 106 106 106h74c19.9 0 36 16.1 36 36s-16.1 36-36 36zM685 691h-74c-19.9 0-36-16.1-36-36s16.1-36 36-36h74c58.4 0 106-47.6 106-106s-47.6-106-106-106h-73c-19.9 0-36-16.1-36-36s16.1-36 36-36h73c98.1 0 178 79.9 178 178s-79.9 178-178 178z"
              p-id="15917"
              data-spm-anchor-id="a313x.search_index.0.i3.51fd3a81VJzPo0"
              class="selected"
              fill="#e6e6e6"
            ></path>
            <path
              d="M637 549H390c-19.9 0-36-16.1-36-36s16.1-36 36-36h247c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
              p-id="15918"
              data-spm-anchor-id="a313x.search_index.0.i4.51fd3a81VJzPo0"
              class="selected"
              fill="#e6e6e6"
            ></path>
          </svg>
        </div>
        <div class="usdt-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 30 30" fill="none">
            <g clip-path="url(#clip0_82_1159)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.53366 3H23.8134C24.2257 3 24.6066 3.22056 24.8121 3.5784L29.8464 12.3392C30.1076 12.7936 30.03 13.367 29.6575 13.7354L15.8005 27.445C15.3516 27.8892 14.6296 27.8892 14.1807 27.445L0.342436 13.754C-0.0386015 13.377 -0.109953 12.7868 0.170244 12.3296L5.5515 3.55029C5.76108 3.20838 6.13298 3 6.53366 3ZM21.5823 6.91142V9.3696H16.6599V11.0739C20.1171 11.256 22.7108 12.0041 22.73 12.9007V14.7698C22.7106 15.6664 20.1171 16.4145 16.6599 16.5966V20.7792H13.3915V16.5966C9.93435 16.4145 7.34062 15.6664 7.32142 14.7698L7.32149 12.9007C7.34075 12.0041 9.93435 11.256 13.3915 11.0739V9.3696H8.46915V6.91142H21.5823ZM15.0257 15.3124C18.7151 15.3124 21.7987 14.68 22.5532 13.8353C21.9133 13.119 19.5985 12.5552 16.6599 12.4004V14.1848C16.1332 14.2126 15.5865 14.2272 15.0257 14.2272C14.4649 14.2272 13.9182 14.2126 13.3915 14.1848V12.4004C10.4528 12.5552 8.13801 13.119 7.49807 13.8353C8.2527 14.68 11.3364 15.3124 15.0257 15.3124Z"
                fill="#009393"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.5821 6.91113V9.36932H16.6597V11.0736C20.1169 11.2558 22.7106 12.0038 22.7299 12.9004L22.7298 14.7695C22.7106 15.6662 20.1169 16.4142 16.6597 16.5963V20.7789H13.3913V16.5963C9.93421 16.4142 7.34049 15.6662 7.32129 14.7695L7.32135 12.9004C7.34061 12.0038 9.93421 11.2558 13.3913 11.0736V9.36932H8.46901V6.91113H21.5821ZM22.5532 13.835C21.7986 14.6797 18.7149 15.3122 15.0256 15.3122C11.3362 15.3122 8.25256 14.6797 7.49791 13.835C8.13786 13.1187 10.4526 12.5549 13.3913 12.4001V14.1845C13.918 14.2123 14.4648 14.2269 15.0256 14.2269C15.5863 14.2269 16.1331 14.2123 16.6597 14.1845V12.4001C19.5985 12.5549 21.9132 13.1187 22.5532 13.835Z"
                fill="white"
              />
              <path
                d="M22.5 30C26.6421 30 30 26.6421 30 22.5C30 18.3579 26.6421 15 22.5 15C18.3579 15 15 18.3579 15 22.5C15 26.6421 18.3579 30 22.5 30Z"
                fill="#EB322A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.0651 18.1109C18.1317 18.028 18.2376 17.987 18.3427 18.0037L25.8717 19.1989C25.9359 19.2091 25.9948 19.24 26.0397 19.2871L27.8596 21.1964C27.8943 21.2318 27.9202 21.2759 27.9337 21.3262C27.9384 21.3431 27.9414 21.3605 27.9429 21.3781C27.9468 21.4222 27.9406 21.4651 27.9264 21.5045C27.9153 21.535 27.8991 21.5641 27.8776 21.5905L22.4101 28.3129C22.3437 28.3945 22.239 28.4347 22.135 28.4186C22.0311 28.4026 21.9433 28.3325 21.9048 28.2347L18.0207 18.4052C17.9817 18.3064 17.9986 18.194 18.0651 18.1109ZM27.0355 21.1907L25.8385 19.9349L23.9217 21.6298L27.0355 21.1907ZM25.2157 19.6946L19.3143 18.7579L22.9939 21.6592L25.2157 19.6946ZM23.248 22.3232L26.9415 21.8024L22.6333 27.0992L23.248 22.3232ZM22.05 26.9899L18.9964 19.2619L22.6716 22.1597L22.05 26.9899Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_82_1159">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div class="notice">
      <div class="notice-ico">
        <svg
          t="1697879989974"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="7546"
          width="36"
          height="36"
        >
          <path
            d="M619.349333 343.722667a231.253333 231.253333 0 0 0-256-108.885334l-10.922666-19.285333a64.341333 64.341333 0 1 0-111.445334 64.341333l11.093334 19.114667a231.594667 231.594667 0 0 0-34.133334 276.138667l162.986667 282.112 401.237333-231.594667z"
            fill="#FFC670"
            p-id="7547"
          ></path>
          <path
            d="M819.2 560.128a25.770667 25.770667 0 0 0-38.570667-22.357333l-229.888 132.437333-98.986666 57.173333a85.333333 85.333333 0 0 1-115.882667-31.061333l-65.706667-114.005333A256 256 0 0 1 273.066667 318.634667a210.261333 210.261333 0 0 1 111.104-87.893334 230.4 230.4 0 0 0-109.397334 47.957334 237.226667 237.226667 0 0 0-51.2 302.933333l120.490667 208.725333-21.845333 11.776a40.106667 40.106667 0 0 0-17.066667 51.2A38.4 38.4 0 0 0 358.4 870.4l22.357333-12.8 24.405334-14.165333L779.946667 627.029333a85.333333 85.333333 0 0 0 39.253333-66.901333z"
            fill="#FFE88A"
            p-id="7548"
          ></path>
          <path
            d="M779.946667 537.770667l-36.352 21.162666-124.245334-215.210666a231.424 231.424 0 0 0-316.245333-85.333334c-6.314667 3.584-12.288 7.509333-18.090667 11.605334A231.594667 231.594667 0 0 1 554.666667 375.466667l69.632 120.661333a113.834667 113.834667 0 0 1-41.642667 155.477333L460.8 722.432l-70.826667 40.96L322.218667 802.133333a40.106667 40.106667 0 0 0-17.066667 51.2A38.4 38.4 0 0 0 358.4 870.4l22.357333-12.8 100.693334-58.197333a115.882667 115.882667 0 0 0 199.509333-115.2l100.693333-58.197334A80.896 80.896 0 0 0 819.2 563.2v-2.56a25.770667 25.770667 0 0 0-39.253333-22.869333z"
            fill="#FF9A42"
            p-id="7549"
          ></path>
          <path
            d="M621.568 698.026667m-69.632 0a69.632 69.632 0 1 0 139.264 0 69.632 69.632 0 1 0-139.264 0Z"
            fill="#FFC670"
            p-id="7550"
          ></path>
          <path
            d="M550.741333 670.208L765.952 546.133333a38.570667 38.570667 0 0 1 52.565333 14.165334 38.570667 38.570667 0 0 1-13.994666 52.736L358.4 870.4a38.570667 38.570667 0 0 1-52.736-14.165333 38.570667 38.570667 0 0 1 14.165333-52.565334l69.12-39.936"
            fill="#FF9A42"
            p-id="7551"
          ></path>
          <path
            d="M536.234667 148.821333A24.576 24.576 0 0 0 512 173.397333V392.533333a12.288 12.288 0 0 0 20.992 8.704l57.173333-57.002666h221.866667A24.746667 24.746667 0 0 0 836.266667 318.976v-145.578667a24.576 24.576 0 0 0-24.746667-24.576z"
            fill="#96DDFF"
            p-id="7552"
          ></path>
          <path
            d="M532.48 400.725333l57.173333-57.002666h221.866667A24.746667 24.746667 0 0 0 836.266667 318.976v-145.578667a24.576 24.576 0 0 0-24.746667-24.576h-12.8c0 48.810667-3.072 127.829333-26.453333 127.829334h-221.866667A51.2 51.2 0 0 0 512 325.973333V392.533333a12.288 12.288 0 0 0 20.48 8.192z"
            fill="#69BAF9"
            p-id="7553"
          ></path>
          <path
            d="M812.032 131.754667H536.234667A41.813333 41.813333 0 0 0 494.933333 173.397333v49.322667a245.589333 245.589333 0 0 0-122.538666-7.509333l-4.778667-8.192a81.408 81.408 0 0 0-140.970667 81.408l4.949334 8.533333a248.661333 248.661333 0 0 0-27.477334 286.72L319.317333 785.066667l-7.509333 4.266666a55.637333 55.637333 0 0 0 55.637333 96.426667L477.866667 821.077333a132.778667 132.778667 0 0 0 224.426666-129.536l110.592-64a55.637333 55.637333 0 0 0-55.637333-96.256l-7.509333 4.266667L648.533333 360.618667h163.157334A41.813333 41.813333 0 0 0 853.333333 318.976v-145.578667a41.813333 41.813333 0 0 0-41.301333-41.642666zM273.066667 206.848a47.274667 47.274667 0 0 1 64.512 17.066667 265.386667 265.386667 0 0 0-43.349334 19.456A254.464 254.464 0 0 0 256 271.701333a47.104 47.104 0 0 1 17.066667-64.853333z m351.914666 609.621333a98.133333 98.133333 0 0 1-116.394666-12.970666l163.328-94.378667a97.962667 97.962667 0 0 1-46.933334 107.349333z m95.232-263.850666l-178.005333 102.4a17.066667 17.066667 0 0 0-6.314667 23.210666 17.066667 17.066667 0 0 0 23.381334 6.314667l215.210666-124.245333A21.504 21.504 0 1 1 795.989333 597.333333L350.378667 855.381333a21.504 21.504 0 0 1-21.504-37.376l69.12-39.936a17.066667 17.066667 0 1 0-17.066667-29.525333L348.842667 768l-115.712-201.386667a214.698667 214.698667 0 0 1 78.506666-293.546666A211.968 211.968 0 0 1 494.933333 259.072V392.533333a29.184 29.184 0 0 0 17.066667 26.624 28.672 28.672 0 0 0 32.085333-6.314666L597.333333 360.618667h12.8zM819.2 318.976a7.68 7.68 0 0 1-7.68 7.509333h-221.866667a17.066667 17.066667 0 0 0-12.117333 5.12L529.066667 380.586667V173.397333a7.68 7.68 0 0 1 7.68-7.509333h275.285333a7.68 7.68 0 0 1 7.68 7.509333z"
            fill="#3D3D63"
            p-id="7554"
          ></path>
          <path
            d="M591.530667 232.789333h51.2a17.066667 17.066667 0 0 0 0-34.133333h-51.2a17.066667 17.066667 0 0 0 0 34.133333zM738.304 259.584h-146.773333a17.066667 17.066667 0 0 0 0 34.133333h146.773333a17.066667 17.066667 0 0 0 0-34.133333zM451.754667 707.754667l-14.848 8.533333a17.066667 17.066667 0 1 0 17.066666 29.525333l14.848-8.533333a17.066667 17.066667 0 0 0 6.144-23.381333 17.066667 17.066667 0 0 0-23.210666-6.144z"
            fill="#3D3D63"
            p-id="7555"
          ></path>
        </svg>
      </div>
      <div class="notice-text">公告：使用说明请查看最下方的解释说明！</div>
    </div>
    <div class="card">
      <div class="card-top">
        <div class="card-title">
          <div class="bag">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" class="image">
              <g fill-rule="evenodd" fill="none" id="Page-1">
                <g fill-rule="nonzero" id="059---Money-Bag">
                  <path
                    fill="#f29c1f"
                    d="m23 16.98v-9.98c0-1.66-2.91-3-6.5-3s-6.5 1.34-6.5 3v11.01c-3.36.12-6 1.41-6 2.99v26.23c-2.35.45-4 1.52-4 2.77v5c0 1.66 2.91 3 6.5 3s6.5-1.34 6.5-3v-2c0 1.66 2.91 3 6.5 3s6.5-1.34 6.5-3l.5-13z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f3d55b"
                    d="m54.527 13.355c-.072-.108-1.461-2.092-5.5-3 1.0331933-.84954961 1.6441867-2.10769199 1.673-3.445 0-2.358-2.006-4.417-4.769-4.895-.5372791-.08053169-1.0405693.28263221-1.1334952.81790692s.2585108 1.04682267.7914952 1.15209308c1.798.315 3.111 1.542 3.111 2.925 0 1.638-1.775 2.984-4 3-.8755119.01021562-1.7370699-.21978683-2.491-.665-.4719273-.28691192-1.0870881-.13692731-1.374.33500001-.2869119.47192729-.1369273 1.08708809.335 1.37399999 1.0594655.6270481 2.2688888.9556657 3.5.951.01 0 .017.005.026.005 6.213 0 8.124 2.5 8.176 2.567.200426.2958159.5434432.4622351.8998403.436569.356397-.025666.6720289-.239518.828-.561.155971-.3214819.1285857-.7017531-.0718403-.997569z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#a56a43"
                    d="m45.19 52h-19.19v-12c0-1.66-2.91-3-6.5-3-.8387701-.0034358-1.6759363.0735835-2.5.23v-10.89c1.4565382-3.4367258 3.4852949-6.6015863 6-9.36v-.01c2.0874104-2.3329398 4.5726948-4.2764728 7.34-5.74v-.01c.8551132.5204315 1.8390235.7906603 2.84.78h5.6c1.0050057.0070139 1.9920265-.2665813 2.85-.79 2.18 1.08 12.01 6.72 15.56 21.79 4 17-8 19-12 19z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#fdd7ad"
                    d="m45.19 49c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1c.723 0 4.425-.125 6.376-2.589.9225786-1.3035875 1.4145252-2.8629918 1.407-4.46.0332885-.5485091.4978261-.970453 1.047-.951.2649656.0126906.5140321.1301392.6923876.326499.1783554.1963597.2713838.4555393.2586124.720501-.0084762 2.0150456-.6508108 3.9763353-1.836 5.606-2.524 3.186-7.061 3.347-7.945 3.347z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#805333"
                    d="m46 1.17c.01 2.2-.43 7-3.95 9.75-.1318612.1079533-.2723293.2049432-.42.29-.8579735.5234187-1.8449943.7970139-2.85.79h-5.6c-1.0009765.0106603-1.9848868-.2595685-2.84-.78-.1495192-.0908277-.2931396-.191028-.43-.3-3.52-2.75-3.95-7.55-3.95-9.75.0016568-.33258473.1685567-.6425818.4452874-.8270689.2767306-.18448711.6270741-.21931899.9347126-.0929311 1.28.54 1.71 1.75 3.65 1.75 2.49 0 2.49-2 4.98-2s2.5 2 5 2c1.94 0 2.37-1.22 3.65-1.75.3076385-.12638789.657982-.09155601.9347126.0929311.2767307.1844871.4436306.49448417.4452874.8270689z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#603e26"
                    d="m36.45 11.982c1.164119-1.6359812 2.0400633-3.45889226 2.59-5.39.1491169-.53295478.7020452-.84411688 1.235-.695s.8441169.70204522.695 1.235c-.545409 1.83131833-1.3359015 3.5804932-2.35 5.2l3.34 3.12c.4031679.3783151.423315 1.0118321.045 1.415-.3783151.4031678-1.0118321.423315-1.415.045l-3.59-3.35v3.3c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-3.3l-3.58 3.35c-.1844231.1726652-.4273651.2691275-.68.27-.2800466-.0009897-.5474685-.1166316-.74-.32-.3709419-.4048785-.3486888-1.0324134.05-1.41l3.35-3.12c-1.0179074-1.618792-1.8117948-3.36803545-2.36-5.2-.0704836-.2559398-.0363072-.52940237.0949974-.76012341.1313047-.23072105.3489609-.3997642.6050026-.46987659.2546559-.07392838.5283328-.04249768.7595988.08723693.231266.1297346.4007475.34690489.4704012.60276307.5569409 1.93085241 1.4433119 3.7509576 2.62 5.38z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f0c419"
                    d="m17 21c0 1.66-2.91 3-6.5 3s-6.5-1.34-6.5-3c0-1.58 2.64-2.87 6-2.99.17-.01.33-.01.5-.01 3.59 0 6.5 1.34 6.5 3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#e57e25"
                    d="m23 7v9.98c-2.5147051 2.7584137-4.5434618 5.9232742-6 9.36v-5.34c0-1.66-2.91-3-6.5-3-.17 0-.33 0-.5.01v-11.01c0 1.66 2.91 3 6.5 3s6.5-1.34 6.5-3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f9eab0"
                    d="m13 50c0 1.66-2.91 3-6.5 3s-6.5-1.34-6.5-3c0-1.25 1.65-2.32 4-2.77.82406367-.1564165 1.66122992-.2334358 2.5-.23 3.59 0 6.5 1.34 6.5 3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f3d55b"
                    d="m13 50v5c0 1.66-2.91 3-6.5 3s-6.5-1.34-6.5-3v-5c0 1.66 2.91 3 6.5 3s6.5-1.34 6.5-3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f3d55b"
                    d="m26 40c0 1.66-2.91 3-6.5 3s-6.5-1.34-6.5-3c0-1.25 1.65-2.32 4-2.77.8240637-.1564165 1.6612299-.2334358 2.5-.23 3.59 0 6.5 1.34 6.5 3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#f0c419"
                    d="m26 40v13c0 1.66-2.91 3-6.5 3s-6.5-1.34-6.5-3v-13c0 1.66 2.91 3 6.5 3s6.5-1.34 6.5-3z"
                    id="Shape"
                  ></path>
                  <path
                    fill="#fdd7ad"
                    d="m39 27c0 .5522847.4477153 1 1 1s1-.4477153 1-1c0-2.209139-1.790861-4-4-4v-1c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v1c-2.209139 0-4 1.790861-4 4s1.790861 4 4 4v4c-1.1045695 0-2-.8954305-2-2 0-.5522847-.4477153-1-1-1s-1 .4477153-1 1c0 2.209139 1.790861 4 4 4v1c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-1c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4v-4c1.1045695 0 2 .8954305 2 2zm0 6c0 1.1045695-.8954305 2-2 2v-4c1.1045695 0 2 .8954305 2 2zm-4-4c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <div class="orderTitle">生成快速交易订单</div>
        </div>
        <div class="meonyanddata">
          <div>
            <svg
              t="1697822138539"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1232"
              width="35"
              height="35"
            >
              <path
                d="M661.333333 853.333333v64a85.333333 85.333333 0 0 1-85.333333 85.333334H106.666667a85.333333 85.333333 0 0 1-85.333334-85.333334v-64zM661.333333 106.666667v64H21.333333V106.666667a85.333333 85.333333 0 0 1 85.333334-85.333334h469.333333a85.333333 85.333333 0 0 1 85.333333 85.333334z"
                fill="#D0D7DF"
                p-id="1233"
              ></path>
              <path d="M661.333333 853.333333H21.333333V170.666667h640v682.666666z" fill="#35DC86" p-id="1234"></path>
              <path
                d="M661.333333 512v234.666667h298.666667a42.794667 42.794667 0 0 0 42.666667-42.666667v-192H661.333333z"
                fill="#F6B445"
                p-id="1235"
              ></path>
              <path
                d="M960 277.333333H661.333333v234.666667h341.333334v-192a42.794667 42.794667 0 0 0-42.666667-42.666667z"
                fill="#FED049"
                p-id="1236"
              ></path>
              <path
                d="M576 0H106.666667a106.794667 106.794667 0 0 0-106.666667 106.666667v810.666666a106.794667 106.794667 0 0 0 106.666667 106.666667h469.333333a106.794667 106.794667 0 0 0 106.666667-106.666667V277.333333a21.333333 21.333333 0 0 0-42.666667 0v640a64 64 0 0 1-64 64H106.666667a64 64 0 0 1-64-64V106.666667a64 64 0 0 1 64-64h469.333333a64 64 0 0 1 64 64v42.666666H128a21.333333 21.333333 0 0 0 0 42.666667h533.333333a21.333333 21.333333 0 0 0 21.333334-21.333333V106.666667a106.794667 106.794667 0 0 0-106.666667-106.666667z"
                fill="#474C54"
                p-id="1237"
              ></path>
              <path
                d="M106.666667 853.333333a21.333333 21.333333 0 0 0 21.333333 21.333334h426.666667a21.333333 21.333333 0 0 0 0-42.666667H128a21.333333 21.333333 0 0 0-21.333333 21.333333zM960 256H746.666667a21.333333 21.333333 0 0 0 0 42.666667h213.333333a21.333333 21.333333 0 0 1 21.333333 21.333333v106.666667a21.333333 21.333333 0 0 0 42.666667 0v-106.666667a64 64 0 0 0-64-64z"
                fill="#474C54"
                p-id="1238"
              ></path>
              <path
                d="M746.666667 405.333333h149.333333a21.333333 21.333333 0 0 0 0-42.666666h-149.333333a21.333333 21.333333 0 0 0 0 42.666666zM1002.666667 490.666667H746.666667a21.333333 21.333333 0 0 0 0 42.666666h234.666666v170.666667a21.333333 21.333333 0 0 1-21.333333 21.333333H746.666667a21.333333 21.333333 0 0 0 0 42.666667h213.333333a64 64 0 0 0 64-64v-192a21.333333 21.333333 0 0 0-21.333333-21.333333z"
                fill="#474C54"
                p-id="1239"
              ></path>
              <path
                d="M896 640a21.333333 21.333333 0 0 0 0-42.666667h-42.666667a21.333333 21.333333 0 0 0 0 42.666667zM746.666667 640h21.333333a21.333333 21.333333 0 0 0 0-42.666667h-21.333333a21.333333 21.333333 0 0 0 0 42.666667zM256 554.666667a21.333333 21.333333 0 0 0-21.333333 21.333333v21.333333a64 64 0 0 0 64 64h21.333333v21.333334a21.333333 21.333333 0 0 0 42.666667 0v-21.333334h21.333333a64 64 0 0 0 64-64v-42.666666a64 64 0 0 0-64-64h-85.333333a21.333333 21.333333 0 0 1-21.333334-21.333334v-42.666666a21.333333 21.333333 0 0 1 21.333334-21.333334h85.333333a21.333333 21.333333 0 0 1 21.333333 21.333334v21.333333a21.333333 21.333333 0 0 0 42.666667 0v-21.333333a64 64 0 0 0-64-64h-21.333333v-21.333334a21.333333 21.333333 0 0 0-42.666667 0v21.333334h-21.333333a64 64 0 0 0-64 64v42.666666a64 64 0 0 0 64 64h85.333333a21.333333 21.333333 0 0 1 21.333333 21.333334v42.666666a21.333333 21.333333 0 0 1-21.333333 21.333334h-85.333333a21.333333 21.333333 0 0 1-21.333334-21.333334v-21.333333a21.333333 21.333333 0 0 0-21.333333-21.333333z"
                fill="#474C54"
                p-id="1240"
              ></path>
            </svg>
          </div>
          <div>
            <svg
              t="1697822738652"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="91846"
              width="30"
              height="30"
            >
              <path
                d="M560.697 472.022a23 23 0 0 1 13.27 4.214L820.026 650.05c10.375 7.33 12.844 21.681 5.515 32.056a23 23 0 0 1-5.1 5.215L574.383 869.495c-10.21 7.559-24.613 5.41-32.171-4.799a23 23 0 0 1-4.515-13.686V733.816h-201.16c-143.36 0.815-221.206-70.034-233.537-212.546 12.457 13.043 66.902 90 233.537 90 111.09 0 178.144-1.277 201.16-3.828v-112.42c0-12.703 10.297-23 23-23zM406.788 119.304a23 23 0 0 1 4.515 13.686v117.194h201.16c143.36-0.815 221.206 70.034 233.537 212.546-12.457-13.043-66.902-90-233.537-90-111.09 0-178.144 1.277-201.16 3.828v112.42c0 12.703-10.297 23-23 23a23 23 0 0 1-13.27-4.214L128.974 333.95c-10.375-7.33-12.844-21.681-5.515-32.056a23 23 0 0 1 5.1-5.215l246.058-182.173c10.21-7.559 24.613-5.41 32.171 4.799z"
                fill="#515151"
                p-id="91847"
                data-spm-anchor-id="a313x.search_index.0.i35.22ad3a81wSa9YA"
                class=""
              ></path>
            </svg>
          </div>
          <div>
            <svg
              t="1697822226812"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="73094"
              width="40"
              height="40"
            >
              <path
                d="M518.0416 565.5552l-354.56 204.6976V360.8064l354.56-204.6976v409.4464z"
                fill="#DFCFBD"
                p-id="73095"
              ></path>
              <path
                d="M872.6528 770.2528l-354.6112-204.6976V156.1088l354.6112 204.6976v409.4464z"
                fill="#EFE7DF"
                p-id="73096"
              ></path>
              <path
                d="M606.72 180.2752l354.56 204.6976-88.6272-24.1664-354.6112-204.6976 88.6784 24.1664z"
                fill="#DBCBB9"
                p-id="73097"
              ></path>
              <path
                d="M518.0416 156.1088L163.4816 360.8064 47.104 384.9728l354.56-204.6976 116.3776-24.1664z"
                fill="#DBCBB9"
                p-id="73098"
              ></path>
              <path
                d="M758.0672 736.512l-320.2048-184.832V69.3248l320.2048 184.8832v482.304z"
                fill="#EBEBEC"
                p-id="73099"
              ></path>
              <path
                d="M473.1392 48.9984l320.1536 184.8832-35.2256 20.3264-320.2048-184.8832 35.2768-20.3264z"
                fill="#F6F6F6"
                p-id="73100"
              ></path>
              <path
                d="M793.2928 716.1856l-35.2256 20.3264v-482.304l35.2256-20.3264v482.304z"
                fill="#DCDDDD"
                p-id="73101"
              ></path>
              <path
                d="M696.8832 773.2736l-320.2048-184.832v-482.304l320.2048 184.832v482.304z"
                fill="#EBEBEC"
                p-id="73102"
              ></path>
              <path
                d="M411.9552 85.76l320.1536 184.8832-35.2256 20.3264-320.2048-184.832 35.2768-20.3776z"
                fill="#F6F6F6"
                p-id="73103"
              ></path>
              <path
                d="M732.1088 752.9472l-35.2256 20.3264v-482.304l35.2256-20.3264v482.304z"
                fill="#DCDDDD"
                p-id="73104"
              ></path>
              <path
                d="M634.5216 809.6256l-320.2048-184.832V142.4384l320.2048 184.8832v482.304z"
                fill="#EBEBEC"
                p-id="73105"
              ></path>
              <path
                d="M349.5424 122.112l320.2048 184.8832-35.2256 20.3264-320.2048-184.832 35.2256-20.3776z"
                fill="#F6F6F6"
                p-id="73106"
              ></path>
              <path
                d="M288.4608 190.0032l320.1536 184.8832h-35.2256L253.184 190.0032h35.2768z"
                fill="#F2F2F3"
                p-id="73107"
              ></path>
              <path
                d="M514.4064 853.9136h-31.1296l90.112-479.0272h35.2256l-94.208 479.0272z"
                fill="#DCDDDD"
                p-id="73108"
              ></path>
              <path
                d="M507.0336 559.2064v231.4752l67.4816-415.7952-320.2048-184.8832-33.1264 204.1856 285.8496 165.0176z"
                fill="#EBEBEC"
                p-id="73109"
              ></path>
              <path
                d="M669.7472 789.2992l-35.2256 20.3264v-482.304l35.2256-20.3264v482.304z"
                fill="#DCDDDD"
                p-id="73110"
              ></path>
              <path
                d="M872.6528 770.2528l-354.6112 204.7488v-409.4464l354.6112-204.7488v409.4464z"
                fill="#D39F53"
                p-id="73111"
              ></path>
              <path
                d="M976.896 536.7296l-354.56 204.6976-104.2944-175.36 354.6112-204.7488 104.2432 175.4112z"
                fill="#F2E0C7"
                p-id="73112"
              ></path>
              <path
                d="M518.0416 975.0016l-354.56-204.7488V360.8064l354.56 204.7488v409.4464z"
                fill="#E8B674"
                p-id="73113"
              ></path>
              <path
                d="M163.4816 361.3184l354.56 204.7488-88.6272 175.36-354.6112-204.6976 88.6784-175.4112z"
                fill="#F9F1E8"
                p-id="73114"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="card-bot">
        <div class="kuang">
          <div class="kuang-title">请输入等价金额</div>
          <div class="kuang-input">
            <div class="input-container">
              <input required="" placeholder="请输入金额（CNY）...." type="text" v-model="cny" />
              <button class="invite-btn" type="button">CNY</button>
            </div>
          </div>
        </div>
        <div class="kuang-textarea">
          <div class="kuang-title">请输入资源地址</div>
          <textarea name="textarea" placeholder="请输入传输的资源地址...." v-model="urlData"></textarea>
        </div>
      </div>
      <div class="submit" @click="submit">
        <button class="sub">
          <div class="btnico">
            <svg xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <circle cx="16" cy="16" fill="#f7931a" r="16" />
                <path
                  d="M25.977 19.754l-.545 2.183-1.455-.362.544-2.184-2.183-.544.363-1.455 2.183.544.545-2.183 1.455.363-.544 2.183 2.183.544-.363 1.456zM20.61 13.76c-.203 1.35-.939 2.006-1.912 2.24 1.331.703 2.005 1.787 1.348 3.677-.816 2.347-2.726 2.555-5.262 2.08l-.63 2.522-1.486-.37.621-2.49a55.255 55.255 0 0 1-1.184-.307l-.624 2.5-1.484-.37.63-2.528c-.348-.089-.7-.183-1.06-.273l-1.935-.483.748-1.742s1.095.291 1.08.27c.422.103.61-.178.685-.365l.994-3.988.158.039a1.204 1.204 0 0 0-.155-.05l.71-2.847c.02-.323-.087-.729-.69-.88.024-.015-1.079-.268-1.079-.268l.405-1.624 2.05.512-.002.007c.308.077.626.15.95.223l.623-2.498 1.486.37-.611 2.45c.399.09.8.182 1.19.28l.607-2.433 1.487.37-.623 2.499c1.876.65 3.245 1.633 2.965 3.477zm-3.427 4.885c.491-1.97-2.742-2.46-3.75-2.712L12.6 19.28c1.008.25 4.115 1.253 4.585-.636zm.487-4.896c.446-1.793-2.258-2.177-3.097-2.386l-.757 3.037c.839.21 3.425 1.067 3.854-.651z"
                  fill="#fff"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </div>
          <span class="subTitle"> 生成 </span>
        </button>
      </div>
    </div>
    <!-- <div class="Description">
      地方地方地方大师傅犯得上犯得上房贷首付爱上法大师傅大师傅大师傅士大夫大师傅风格的法大师傅大师傅大师傅
    </div> -->
    <div class="jies">
      <a href="https://flowus.cn/share/110b5c6d-d2b3-43bb-a1e5-31c817614853" target="block">点击查看使用说明</a>
    </div>
    <div class="copyright">Copyright © 2023 平头哥. All rights reserved.</div>
  </div>
  <var-popup :default-style="false" v-model:show="success">
    <var-result class="result" type="success" title="成功" description="订单生成成功点击前往~">
      <template #footer>
        <var-button type="success" @click="goUrl">前往</var-button>
      </template>
    </var-result>
  </var-popup>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import { Base64 } from "js-base64";
export default {
  name: "Home",
};
</script>
<script setup>
let url = ref("");
const anchors = [60, Math.round(0.4 * window.innerHeight), Math.round(0.7 * window.innerHeight)];
const height = ref(anchors[0]);
const success = ref(false);
const show = ref(false);
const popTitle = ref(false);
let cny = ref("");
let urlData = ref("");
const submit = () => {
  if (cny.value == "" || urlData.value == "") {
    popTitle.value = true;
  } else {
    testpos();
  }
};

const testpos = () => {
  let baseCny = Base64.encode(cny.value);
  let baseurlData = Base64.encode(urlData.value);
  show.value = true;
  axios({
    method: "POST",
    url: "/api/createUsdt",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      cny: baseCny,
      url: baseurlData,
    },
  })
    .then((res) => {
      // console.log(res.data.data.payment_url);
      show.value = false;
      success.value = true;
      url.value = res.data.data.payment_url;
    })
    .catch((err) => {
      show.value = false;
      // console.log(res.data.data.message);
    });
};

const goUrl = () => {
  success.value = false;
  window.open(url.value);
};
</script>
<style>
a {
  color: #686868;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 60px;
  height: 60px;
}

.atom-spinner,
.atom-spinner * {
  box-sizing: border-box;
}

.atom-spinner {
  height: 60px;
  width: 60px;
  overflow: hidden;
}

.atom-spinner .spinner-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.atom-spinner .spinner-circle {
  display: block;
  position: absolute;
  color: #698be6;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.atom-spinner .spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-duration: 1s;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: #698be6;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;
}

.atom-spinner .spinner-line:nth-child(1) {
  animation: atom-spinner-animation-1 1s linear infinite;
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(2) {
  animation: atom-spinner-animation-2 1s linear infinite;
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(3) {
  animation: atom-spinner-animation-3 1s linear infinite;
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}
.pop {
  background-color: transparent !important;
}
.lygz {
  width: 100%;
  height: 100%;
}
.join {
  animation-name: scale-up-center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: scale-up-center 1s linear 0s 1 normal none;*/
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

.logo {
  padding-top: 6px;
  box-sizing: border-box;
  width: 95%;
  height: 160px;
  /* background-color: #f7f8fa; */
  background-image: linear-gradient(-225deg, #ff057c 0%, #7c64d5 48%, #4cc3ff 100%);
  /* animation: gradient 10s infinite;
  transition: background-color 5s, opacity 1s; */
  margin: 12px auto;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -moz-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.notice {
  width: 95%;
  margin: 12px auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -moz-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.notice-ico {
  width: 36px;
  height: 36px;
}
.notice-text {
  width: auto;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  color: #3c3c3c;
}
/* 背景动画 */
@-webkit-keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* 字体动画 */

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* logo动画 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.ptgorjax {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100px;
}

.ptg-logo,
.usdt-logo {
  width: 80px;
  height: 80px;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.ptg-logo img,
.usdt-logo img {
  width: 100%;
  height: 100%;
}
.title {
  user-select: none;
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #f6f6f6e0;
  margin: 4px auto;
  font-size: 20px;
  font-weight: bold;
  font-family: "ptgFont";
}
@font-face {
  font-family: "ptgFont";
  font-weight: 700;
  src: url("../assets/font/AlimamaShuHeiTi-Bold.woff2") format("woff2"),
    url("../assets/font//AlimamaShuHeiTi-Bold.woff") format("woff");
  font-display: swap;
}

.card {
  /* padding-top: 8px; */
  width: 95%;
  height: 370px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -moz-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.card-top {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-bottom: 10px;
}

.card-title {
  user-select: none;
  padding-right: 8px;
  width: fit-content;
  height: 55px;
  /* border: 1px rgb(247, 247, 247) solid; */
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* margin-left: 8px; */
  /* border-top-right-radius: 8px; */
  /* border-bottom-right-radius: 8px; */
  border-radius: 8px 0 8px 0;
  /* border-left: none; */
  /* border: 1px rgb(224 224 224 / 27%) solid; */
  background-image: linear-gradient(-225deg, #ff057c 0%, #7c64d5 48%, #4cc3ff 100%);
}

.bag {
  width: 38px;
  height: 38px;
  margin-left: 6px;
}
.orderTitle {
  /* color: #6e6e6e; */
  color: #fbfbfb;
  font-family: "ptgFont";
  font-size: 18px;
  padding: 0 6px;
}
.meonyanddata {
  width: 126px;
  height: 56px;
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6px;
}
.card-bot {
  width: 95%;
  height: fit-content;
  margin: 0 auto;
}

.kuang-title {
  user-select: none;
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
  color: #717171;
  font-size: 16px;
  font-weight: bold;
  font-family: "ptgFont";
}
.kuang-title2 {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 4px;
  color: #717171;
  font-size: 16px;
  font-weight: bold;
  font-family: "ptgFont";
  display: block;
}
.kuang-input {
  width: 100%;
  height: 40px;
}
/* 输入框 */
.input-container {
  position: relative;
  display: flex;
  height: 40px;
  width: 100%;
  min-width: 200px;
  background-color: #f7f8fa;
  border-radius: 6px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.05);
}

.input-container input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgb(231 231 231);
  background-color: transparent;
  padding: 0.625rem 70px 0.625rem 0.75rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: rgb(69 90 100);
  outline: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-container input:focus {
  border: 1px solid #9c9c9c;
}

.invite-btn {
  position: absolute;
  width: 65px;
  right: 4px;
  top: 4px;
  bottom: 4px;

  border-radius: 4px;
  background-image: linear-gradient(-225deg, #ff057cb8 0%, #7c64d5bf 48%, #4cc3ff 100%);
  color: #fff;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
}

.input-container input:placeholder-shown ~ .invite-btn {
  pointer-events: none;
  background-color: rgb(173 173 173);
}

/* textarea */
.kuang-textarea {
  width: 100%;
  height: fit-content;
  margin: 16px auto;
}

textarea {
  width: 100%;
  padding: 10px 10px;
  border-radius: 8px;
  resize: none;
  color: #545454;
  height: 96px;
  border: 1px solid rgb(231 231 231);
  background-color: #f7f8fa;
  font-family: "Arial", "Microsoft YaHei", sans-serif;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
  border-color: rgb(173 173 173);
}

/* bitton */
.submit {
  user-select: none;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  /* background-color: #f2f2f2;
  border-radius: 6px;
  color: #545454; */
  background-image: linear-gradient(-225deg, #ff057c 0%, #7c64d5 48%, #4cc3ff 100%);
  border-radius: 6px;
  color: #ffffff;
}
.submit:active {
  color: #ffb357;
}
.sub {
  width: 100px;
  box-sizing: border-box;
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding-left: 6px;
  height: 40px;
  line-height: 40px;
  /* padding: 1.5em 1em 1em 2em; */
  position: relative;
  overflow: hidden;
  font-size: 17px;
  font-family: inherit;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.btnico {
  width: auto;
  height: 40px;
  display: flex;
  align-content: center;
  padding-top: 8px;
}
.btnico svg {
  /* position: absolute; */
  left: 6px;
  width: 40px;
  height: 40px;
  transition: transform 0.1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.sub:active svg {
  transform: rotateY(-10deg) translateX(0.25em);
}

.sub:active {
  transform: scale(0.98);
}
.subTitle {
  text-align: left;
  width: 50px;
  font-size: 16px;
  font-family: "ptgFont";
}
.Description {
  width: 95%;
  margin: 10px auto;
  height: fit-content;
  padding: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -moz-box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0/4%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  text-align: justify;
}
.jies {
  padding: 4px 10px;
  width: fit-content;
  margin: 6px auto;
  user-select: none;
  text-align: center;
  color: #9c9c9cb7;
  font-size: 14px;
  border: 1px #ffffff dashed;
}
.copyright {
  width: fit-content;
  color: #9c9c9cb7;
  font-size: 14px;
  user-select: none;
  text-align: center;
  margin: 10px auto;
}
</style>
