<!--
 * @Author: 羚羊公子
 * @FilePath: \usdtpay\src\App.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
.result {
  width: 75vw !important;
  max-width: 340px !important;
}
.var-snackbar__content {
  text-align: center;
}
.van-floating-panel {
  width: 360px !important;
  margin: auto;
  right: 0px;
  -webkit-box-shadow: 3px 1px 4px 0px rgb(0 0 0/4%);
  -moz-box-shadow: 3px 1px 4px 0px rgb(0 0 0/4%);
  box-shadow: 3px 1px 4px 0px rgb(0 0 0/4%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  z-index: 0 !important;
}
body {
  /* background-color: #f7f8fa; */
  /* background-color: #f5f5f5; */
  background-color: #f7f8fa;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 375px !important;
  margin: 0 auto !important;
}
</style>
