/*
 * @Author: 羚羊公子
 * @FilePath: \usdtpay\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
// 引入你需要的组件
import { Overlay, Toast } from 'vant';
//引入组件样式
import 'vant/lib/index.css';

// varlet/ui
import Varlet from '@varlet/ui'
import '@varlet/ui/es/style'

// 
// 路由
import router from './router/index.js'
const app = createApp(App)
app.use(router)
app.use(Overlay)
app.use(Toast)
app.use(Varlet)
app.mount('#app')
