/*
 * @Author: 羚羊公子
 * @FilePath: \usdtpay\src\router\index.js
 */
import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent } from 'vue'
import Home from '../pages/Home.vue';


const routes = [
  { path: "/", name: "Home", component: Home },

];
const router = createRouter({
  history: createWebHistory(),
  routes

})
export default router;